class ZIDXAccountPageEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountPageEditContainer";
    }
    getMatchURL(){
        return "/account/pages/edit";
    }
    render(){
        // console.log("page edit");
    }
}